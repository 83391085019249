<template>
  <main
    class="
      text-legend-gray
      dark:text-border-color
      xl:px-10
      lg:px-10
      md:px-8
      px-6
      pt-4
      pb-24
      h-screen
      overflow-y-scroll
      overscroll-y-contain
      z-50
      text-sm
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.5x"
      class="
        custom-class
        mb-4
        -ml-4
        -mt-2
        text-primary-color
        cursor-pointer
        sticky
        top
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <!-- Loading spinner -->
    <LoadingSpinner v-if="!singleRedeemable" />
    <section v-else>
      <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
        Redeem Reward
      </h1>
      <div class="text-legend-gray dark:text-border-color mt-3">
        {{ singleRedeemable.redeem.name }}
      </div>
      <div class="flex justify-between items-center my-10 text-sm">
        <div>
          <h1 class="uppercase font-semibold">merchant</h1>
          <div class="text-primary-color mt-1">
            {{
              Object.keys(singleRedeemable).includes('merchant')
                ? singleRedeemable.merchant.name
                : ''
            }}
          </div>
        </div>
        <div
          class="py-2 px-5 rounded-full text-primary-color font-semibold"
          style="background-color: #f7e6de"
        >
          {{ singleRedeemable.redeem.point }} points
        </div>
      </div>
      <div class="my-10">
        <h1 class="uppercase font-semibold">product</h1>
        <div>
          {{
            Object.keys(singleRedeemable).includes('product')
              ? singleRedeemable.product.name
              : singleRedeemable.redeem.name
          }}
        </div>
      </div>
      <div class="my-10">
        <h1 class="uppercase font-semibold">Description</h1>
        <div>
          {{
            Object.keys(singleRedeemable).includes('product')
              ? singleRedeemable.product.description
              : singleRedeemable.redeem.desc
          }}
        </div>
      </div>
      <div
        v-if="singleRedeemable.redeem.value"
        class="flex justify-between my-10 font-semibold"
      >
        <div>
          <h1 class="uppercase text-sm">price</h1>
          <div class="text-xl text-secondary-green mt-1">
            ₦{{ singleRedeemable.redeem.value | moneyFormat }}
          </div>
        </div>
        <!-- <div class="pr-24">
          <h1 class="uppercase text-sm">Discounted Price</h1>
          <div class="text-xl mt-1 text-secondary-green">
            ₦{{ singleRedeemable.redeem.value | moneyFormat }}
          </div>
        </div> -->
      </div>
      <custom-button :loading="loading" @sendAction="redeemReward">
        <span slot="button-text">Redeem</span>
      </custom-button>
    </section>
  </main>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
import errorModule from '@/util/error.handle'

export default {
  name: 'SingleReddemable',
  props: {
    singleRedeemable: {
      type: Object,
      default: null,
    },
  },
  components: {
    XIcon,
  },
  data() {
    return {
      notification: null,
      loading: false,
    }
  },
  methods: {
    async redeemReward() {
      if (this.loading) {
        return
      }

      Object.keys(this.singleRedeemable).includes('product')
        ? this.confirmRedeemPost()
        : this.confirmRedeemGet()
    },
    async confirmRedeemGet() {
      try {
        this.loading = true
        const { status, data } = await this.axios.get(
          `/account/rewards/redeem/${this.singleRedeemable.redeem.id}/confirm`
        )
        if (status === 200) {
          this.$emit('redeemSuccess', data.message)
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async confirmRedeemPost() {
      try {
        this.loading = true
        const { status, data } = await this.axios.post(
          `/account/rewards/redeem/${this.singleRedeemable.redeem.id}/confirm`,
          {
            product_id: this.singleRedeemable.product.id,
          }
        )

        if (status === 200) {
          this.$emit('responseMessage', {
            message: data.message,
            type: 'success',
          })
          this.loading = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },
  },
}
</script>
