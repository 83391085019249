<template>
  <main class="bg-transparent xl:px-6 lg:px-6 md:px-8 px-3 mb-16">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <section class="w-full">
      <h1 class="text-xl font-bold capitalize">reward points</h1>
      <section
        class="
          flex
          xl:flex-row
          lg:flex-row
          md:flex-col
          flex-col
          justify-between
          xl:w-4/5
          lg:w-11/12
          md:w-11/12
          w-full
          p-2
          mt-2
          rounded
        "
        style="background-color: rgb(83, 144, 41, 0.1)"
      >
        <div class="flex pl-5 pr-20 py-5 bg-altertive-green text-white">
          <img
            src="@/assets/images/reward-pack.svg"
            alt="current reward points"
          />
          <div class="ml-5">
            <div class="text-sm">Total Reward points</div>
            <div class="font-bold mt-3">{{ rewardPoints }}</div>
          </div>
        </div>
        <div class="px-8 py-5 text-altertive-green">
          <div class="font-bold">Referral ID</div>
          <div class="mt-3 text-sm">{{ user.username }}</div>
        </div>
        <div class="px-8 py-5 text-altertive-green relative flex-shrink-0">
          <div class="flex">
            <span class="font-bold">Referral link</span>
            <div
              class="flex items-center ml-5 cursor-pointer"
              @click="copyToClipboard"
            >
              <img
                src="@/assets/images/copy-icon.svg"
                alt="copy referal link"
              />
              <span
                class="
                  ml-1
                  font-light
                  text-legend-gray
                  dark:text-border-color
                  text-sm
                "
                >Copy</span
              >
            </div>
          </div>
          <div class="referal-link mt-3 text-sm">
            {{ refferalLink }}
          </div>
          <div
            v-if="copyMessage"
            class="
              inline-block
              capitalize
              text-xs text-legend-gray
              font-semibold
              bg-gray-100
              py-1
              px-3
              rounded-full
              absolute
              bottom-10
              right-6
            "
          >
            copied!
          </div>
        </div>
      </section>
    </section>
    <section class="w-full mt-12">
      <h1 class="text-lg font-semibold capitalize mb-2">Reward Products</h1>
      <!-- Loading spinner -->
      <LoadingSpinner v-if="!redeemables" />
      <section
        v-else
        class="
          grid
          xl:grid-cols-5
          lg:grid-cols-4
          md:grid-cols-3
          grid-cols-1
          gap-5
        "
      >
        <div
          v-for="item in redeemables"
          :key="item.id"
          class="
            bg-white
            dark:bg-dark-mode-primary
            shadow-md
            rounded-sm
            p-6
            cursor-pointer
          "
          @click="fetchSingleRedeemableItem(item.id)"
        >
          <img :src="getImgUrl()" alt="legend pay logo" class="w-24" />
          <div
            class="
              inline-block
              mt-8
              text-xs text-primary-red
              py-1
              px-3
              rounded-full
              bg-red-100
              dark:bg-opacity-10
            "
          >
            {{ item.point }} points
          </div>
          <div class="text-sm text-legend-gray dark:text-border-color mt-3">
            {{ item.name }}
          </div>
        </div>
      </section>
    </section>
    <section
      v-if="redeemables"
      class="xl:w-3/5 lg:w-full md:w-full w-full mt-12"
    >
      <h1 class="text-lg font-semibold capitalize mb-2">
        Reward point history
      </h1>
      <div
        class="
          table
          w-full
          bg-white
          dark:bg-dark-mode-primary
          text-sm
          shadow-md
        "
      >
        <div class="table-row-group">
          <div class="table-row">
            <div class="table-cell py-4 px-5 font-semibold">SN</div>
            <div class="table-cell py-4 px-5 font-semibold">Points</div>
            <div class="table-cell py-4 px-5 font-semibold">Reference</div>
            <div class="table-cell py-4 px-5 font-semibold">Time stamp</div>
          </div>
          <div
            v-for="(item, index) in rewardHistory"
            :key="index"
            class="
              data-items
              table-row
              text-legend-gray
              dark:text-border-color dark:border-b dark:border-border-color
            "
          >
            <div class="table-cell py-4 px-5">{{ index + 1 }}</div>
            <div class="table-cell py-4 px-5">{{ item.point_summation }}</div>
            <div class="table-cell py-4 px-5">{{ item.reference }}</div>
            <div class="table-cell py-4 px-5">
              {{ item.created_at | moment('MMM D, YYYY') }} •
              {{ item.created_at | moment('h:mm a') }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <custom-modal v-if="viewRedeemable">
      <SingleRedeemable
        :singleRedeemable="singleRedeemable"
        slot="cart"
        @closeModal="viewRedeemable = false"
        @responseMessage="responseMessage"
      />
    </custom-modal>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import SingleRedeemable from '@/components/SingleRedeemableItem.vue'
import errorModule from '@/util/error.handle'

export default {
  name: 'RewardPoints',
  components: { SingleRedeemable },
  mounted() {
    this.stripeTable()
    this.fetchRewardPoints()
    this.fetchRedeemableItems()
    this.fetchRewardHistory()
  },
  data() {
    return {
      redeemables: null,
      rewardHistory: [],
      viewRedeemable: false,
      singleRedeemable: null,
      notification: null,
      copyMessage: null,
    }
  },
  computed: {
    ...mapGetters(['rewardPoints', 'user', 'mode']),
    refferalLink() {
      return `${window.location.origin}/register?referral_id=${this.user.username}`
    },
  },
  watch: {
    mode() {
      this.stripeTable()
    },
  },
  methods: {
    stripeTable() {
      const nodes = document.querySelectorAll('.data-items')
      nodes.forEach((node, index) => {
        if (this.mode !== 'dark' && index % 2 === 0) {
          node.classList.add('bg-gray-100')
        } else if (this.mode === 'dark') {
          node.classList.remove('bg-gray-100')
        }
      })
    },
    getImgUrl() {
      const imagePath =
        this.mode === 'dark'
          ? 'legend-pay-logo-light.svg'
          : 'legend-pay-logo-dark.svg'
      return require(`@/assets/images/${imagePath}`)
    },
    responseMessage(response) {
      this.notification = {
        type: response.type,
        message: response.message,
      }
      this.viewRedeemable = false
    },
    async fetchRewardPoints() {
      try {
        const { status, data } = await this.axios.get('/account/rewards')

        if (status === 200) {
          this.$store.commit('SET_REWARD_POINTS', data.data.point)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchRedeemableItems() {
      try {
        const { status, data } = await this.axios.get('/account/rewards/redeem')
        if (status === 200) {
          this.redeemables = data.data
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchSingleRedeemableItem(id) {
      try {
        this.viewRedeemable = true
        this.singleRedeemable = null

        const { status, data } = await this.axios.get(
          `/account/rewards/redeem/${id}`
        )

        if (status === 200) {
          this.singleRedeemable = data.data
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchRewardHistory() {
      try {
        const { status, data } = await this.axios.get(
          '/account/rewards/history'
        )

        if (status === 200) {
          this.rewardHistory = data.data.history
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    copyToClipboard() {
      const text = document.querySelector('.referal-link').textContent.trim()
      navigator.clipboard.writeText(text).then(() => {
        this.copyMessage = 'Copied!'

        setTimeout(() => {
          this.copyMessage = null
        }, 2000)
      })
    },
  },
}
</script>
